<template>
  <div>
    <v-simple-table fixed-header height="calc(100vh - 145px)" class="table-padding-2">
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th role="columnheader" class="text-center">
              <SelectPos :label="$t('labels.pos')" :placeholder="$t('labels.pos')" name="id_pos"
                sort-name="pos_code" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange"
                @onSort="onSortChange" />
            </th>
            <th role="columnheader" class="text-center">
              <InputFilter :label="$t('labels.customer')" :placeholder="$t('labels.customer')" name="company_name"
                sort-name="company_name" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange"
                @onSort="onSortChange" />
            </th>
            <th role="columnheader" class="text-center">
              <InputFilter :label="$t('labels.shop')" :placeholder="$t('labels.shop')" name="customer_e_market_shop_name"
                sort-name="customer_e_market_shop_name" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange"
                @onSort="onSortChange" />
            </th>
            <th role="columnheader" class="text-center">
              <SelectDeliveryCompany :label="$t('labels.delivery_company')" :placeholder="$t('labels.delivery_company')"
                name="id_delivery_company" sort-name="delivery_company_name" has-sort :sorting="filters.sort_by"
                @onFilter="onFilterChange" @onSort="onSortChange" />
            </th>
            <th role="columnheader" class="text-center">
              <InputFilter :label="$t('labels.tracking')" :placeholder="$t('labels.tracking')" name="tracking_id"
                sort-name="tracking_id" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange"
                @onSort="onSortChange" />
            </th>
            <th role="columnheader" class="text-center">
              <InputFilter :label="$t('labels.customer_order_id')" :placeholder="$t('labels.customer_order_id')"
                name="customer_order_id" sort-name="customer_order_id" has-sort :sorting="filters.sort_by"
                @onFilter="onFilterChange" @onSort="onSortChange" />
            </th>
            <th role="columnheader" class="text-center">
              <InputFilter :label="$t('labels.delivery_order_id')" :placeholder="$t('labels.delivery_order_id')"
                name="delivery_order_id" sort-name="delivery_order_id" has-sort :sorting="filters.sort_by"
                @onFilter="onFilterChange" @onSort="onSortChange" />
            </th>
            <th role="columnheader" class="text-center">
              <DateRangeFilter :label="$t('labels.created_date')" :placeholder="$t('labels.created_date')" name="create_time"
                sort-name="create_time" has-sort :sorting="filters.sort_by" :default-value="defaultToday()"
                @onFilter="onFilterChange" @onSort="onSortChange" />
            </th>
            <th role="columnheader" class="text-center">
              <SelectFilter :options="stateOptions" :label="$t('labels.warehouse_state')"
                :placeholder="$t('labels.warehouse_state')" name="state" sort-name="goods_issue_state_name" has-sort
                :sorting="filters.sort_by" @onFilter="onFilterChange" @onSort="onSortChange" />
            </th>
            <th role="columnheader" class="text-center">
              <InputFilter :label="$t('labels.delivery_partner_state')" :placeholder="$t('labels.delivery_partner_state')"
                name="delivery_partner_state" sort-name="delivery_partner_state" has-sort :sorting="filters.sort_by"
                @onFilter="onFilterChange" @onSort="onSortChange" />
            </th>
            <th role="columnheader" class="text-center">
              <SelectFilter :options="deliveryStateOption" :label="$t('labels.delivery_state')"
                :placeholder="$t('labels.delivery_state')" name="id_delivery_state" sort-name="delivery_state_name"
                has-sort :sorting="filters.sort_by" @onFilter="onFilterChange" @onSort="onSortChange" />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item.id" class="text-center">
            <td>{{ item.pos_code }}</td>
            <td>{{ item.company_name }}</td>
            <td>{{ item.customer_e_market_shop_name }}</td>
            <td>{{ item.delivery_company }}</td>
            <td>
              <OrderTracking :tracking-id="item.tracking_id" />
            </td>
            <td>{{ item.customer_order_id }}</td>
            <td>
              <a v-if="item.delivery_tracking_link" :href="urlTrackingDelivery(item)" target="_blank">{{
                item.delivery_order_id }}</a>
              <span v-else>{{ item.delivery_order_id }}</span>
            </td>
            <td>{{ formatDateTime(item.create_time) }}</td>
            <td>{{ item.goods_issue_state_name }}</td>
            <td>{{ item.delivery_partner_state }}</td>
            <td>{{ item.delivery_state_name }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-row class="pt-3">
      <v-col cols="12" sm="3">
        <div class="d-flex table-footer-count mt-3">
          <div class="mr-2">{{ $t('labels.order') }}: <b>{{ formatNumber(sum.sumOrder) }}</b></div>
        </div>
      </v-col>
      <v-col cols="12" sm="6" class="text-right">
        <v-pagination v-model="page" :length="totalPage" :total-visible="6"></v-pagination>
      </v-col>
      <v-col cols="3">
        <div class="d-flex align-center">
          <v-btn color="success" @click="exportExcel" v-if="checkPermission(['goods_issue_excel'])"
            style="height: 40px;" class="mr-2">
            <v-icon>mdi-download</v-icon>
            {{ $t('labels.excel') }}
          </v-btn>
          <v-text-field dense outlined hide-details v-model.number="page" :label="$t('labels.page')"
            :placeholder="$t('labels.page')"></v-text-field>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { GOODS_ISSUE_STATE, DELIVERY_STATE } from "@/libs/const";
import { httpClient } from "@/libs/http";
import { debounce } from "lodash/function";
import moment from "moment";

export default {
  components: {
    SelectPos: () => import('@/components/table/SelectPos'),
    SelectFilter: () => import('@/components/table/SelectFilter'),
    InputFilter: () => import('@/components/table/InputFilter'),
    DateRangeFilter: () => import('@/components/table/DateRangeFilter'),
    SelectDeliveryCompany: () => import('@/components/table/SelectDeliveryCompany'),
    OrderTracking: () => import('@/components/common/OrderTracking'),
  },
  name: "Index",
  data: () => ({
    page: 1,
    totalPage: 1,
    items: [],
    filters: {
      create_time: [
        moment().format('YYYY-MM-DD'),
        moment().format('YYYY-MM-DD')
      ]
    },
    sum: {},
    stateOptions: [...GOODS_ISSUE_STATE],
    deliveryStateOption: [...DELIVERY_STATE],
    isLoading: false
  }),
  watch: {
    page() {
      this.getList()
    },
    filters: {
      handler() {
        this.page = 1
        this.getList()
      },
      deep: true
    }
  },
  methods: {
    defaultToday() {
      return [
        moment().format('YYYY-MM-DD'),
        moment().format('YYYY-MM-DD')
      ]
    },
    getList: debounce(function () {
      httpClient.post('/order-online-list', { ...this.filters, page: this.page }).then(({ data }) => {
        this.totalPage = data.totalPage
        this.items = [...data.rows]
        this.sum = { ...data.sum }
      })
    }, 1000),
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value }
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort }
    },
    async exportExcel() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t('messages.waiting_file_download'))
        return false
      }
      this.isLoading = true
      const filename = 'xuat-kho.xlsx'

      try {
        await this.downloadExcelFile('/order-online-list-export', { ...this.filters }, filename)
        this.isLoading = false
      } catch (e) {
        this.isLoading = false
      }
    },
    urlTrackingDelivery(item) {
      let tracking_link = item.delivery_tracking_link || ''
      if (tracking_link && tracking_link.includes('[delivery_order_id]')) {
        return tracking_link.replace('[delivery_order_id]', item.delivery_order_id)
      } else {
        return `${tracking_link + item.delivery_order_id}`
      }
    },
  },
  mounted() {
    this.getList()
  }
}
</script>

<style scoped></style>
